<template>
  <b-row align-v="end">
    <b-col md="12">
      <h4>Tipos de pago por contrato</h4>
    </b-col>

    <b-col>
      <ValidationObserver ref="guarenteedAmountForm" v-if="!contractIsCancelled" v-slot="{ invalid }" :disabled="true">
        <b-row align-v="end">
          <b-col>
            <ValidationProvider name="Precio neto" :rules="{required: existsGuaranteedPeriod, max:13}">
              <b-form-group slot-scope="{ errors }" class="mb-0">
                <label>Monto anual garantizado*</label>
                <b-input-group prepend="$">
                  <b-form-input
                    @keypress="onlyNumberWithDecimal"
                    type="text"
                    @blur="formatBudget(contract.guaranteedPayment, 'guaranteedAmount')"
                    placeholder="0.00"
                    v-model="contract.guaranteedPayment.guaranteedAmount"
                    :disabled="isSavingGuaranteedAmount"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col>
            <ValidationProvider rules="required" :name="`Periodicidad`">
              <b-form-group slot-scope="{ errors }" class="m-0">
              <b-form-select
                v-model="contract.guaranteedPayment.periodicity"
                :disabled="isSavingGuaranteedAmount"
                :state="errors.length > 0 ? false : null"
              >
                <option :value="null" disabled>Seleccione una periodicidad</option>
                <option v-for="period in periodicityOptions" :key="period.id" :value="period.id">{{ period.name }}</option>
              </b-form-select>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="auto">
            <b-button
              @click.prevent="saveGuaranteedAmount()"
              variant="primary"
              :disabled="isSavingGuaranteedAmount || invalid"
            >
              <b-spinner v-if="isSavingGuaranteedAmount" small></b-spinner> <feather-icon v-else icon="SaveIcon" size="14"/>
            </b-button>
          </b-col>
        </b-row>
      </ValidationObserver>
      <div v-else class="pl-0">
        <label>Monto anual garantizado</label> <br>
        <span class="font-weight-bolder">{{ formatCurrency(contract.guaranteedPayment.guaranteedAmount) }}</span>
      </div>
    </b-col>


    <b-col>
      <b-button
        v-if="hasGuaranteedPayments"
        class="fives-club-excel-btn float-right"
        @click.prevent="getGuaranteedPaymentsReport()"
        :disabled="isDownloadingReport"
      >
        <b-spinner v-if="isDownloadingReport" small></b-spinner> <feather-icon v-else icon="FileTextIcon" size="14"/>
      </b-button>
    </b-col>
    <b-col v-if="!contractIsCancelled && typepayments.length < 1" md="auto">
      <b-button
        type="button"
        class="float-right"
        variant="primary"
        @click="addPaymentType"
      > <feather-icon icon="PlusIcon" size="14" />
      </b-button>
    </b-col>
    <b-col cols="12" class="mt-1">
      <b-table
        v-if="typepayments.length"
        responsive
        :items="typepayments"
        :fields="tableColumnsAuto"
        show-empty
        empty-text="No se encontraron registros"
        class="position-relative mb-0 payment-type-table payment-type-table-font-size"
        primary-key="id"
        sticky-header="400px"
        fixed
        small
        :busy.sync="isLoadingPaymentTypes"
        style="overflow: auto"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner> <br>
            <strong>Cargando tipos de pago</strong>
          </div>
        </template>

        <template #cell(actions)="row">

          <b-dropdown variant="primary" size="sm" no-caret right :disabled="isDownloadingReport == row.item.id || isDeletingPaymentType == row.item.id" v-if="!contractIsCancelled">
            <template #button-content>
              <b-spinner v-if="isDeletingPaymentType == row.item.id" small/> <feather-icon v-else icon="MoreHorizontalIcon" size="14" />
            </template>

            <b-dropdown-item @click.prevent="editPaymentType(row.item.id)">
              <feather-icon icon="Edit2Icon" size="12" />
              <span class="align-middle ml-50">Editar registro</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="deletePaymentType(row.item.id)">
              <feather-icon icon="TrashIcon" size="12" />
              <span class="align-middle ml-50">Borrar registro</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div v-else-if="isLoadingPaymentTypes" class="text-center">
        <b-spinner /> <br> Cargando tipos de pago para este contrato.
      </div>

      <div v-else>
        <b-alert show variant="warning" class="p-1 text-center">No hay tipos de pago para este contrato.</b-alert>
      </div>
    </b-col>
    <b-col md="12" class="mt-2 pb-0">
      <b-button
        class="float-right"
        v-if="isUpdating"
        variant="primary"
        @click="updatePayments(contract)"
      >
        Actualizar
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import flatPickr from 'vue-flatpickr-component'
import { acl } from "@/modules/auth/mixins/acl"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { toCurrency, formatDateSpecific, numberWithCommas } from "@/helpers/helpers"
import { guaranteedPaymentsPeriodicity } from '@/data/fivesClub'

export default {
	mixins: [utils, acl],
  props: {
    contract: {
      type: Object,
      required: true,
    },
    typepayments: {
      type: Array,
      required: true,
    },
    isLoadingPaymentTypes: {
      type: Boolean,
      default: false
    },
    contractIsCancelled: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  components: {
    flatPickr
  },
  data() {
    return {
      tableColumns: [
        { key: 'dateInit', label: 'Fecha de Inicio', class: 'custom-padding-payment-types text-center font-weight-bolder', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        } },
        { key: 'dateEnd', label: 'Fecha de Término', class: 'custom-padding-payment-types text-center font-weight-bolder', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        } },
        { key: 'paymenttypename', label: 'Tipo de pago', class: 'custom-padding-payment-types text-center' },
        { key: 'returntypename', label: 'Tipo de retorno', class: 'custom-padding-payment-types text-center' },
      ],
      isUpdating: false,
      isDeletingPaymentType: false,
      isDownloadingReport: false,
      periodicityOptions: guaranteedPaymentsPeriodicity,
      isSavingGuaranteedAmount: false
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('fivesClubContracts', ['contractstatus','contracts','requestContracts']),
    showStatusContract() {
      return this.contractstatus
    },
    existsGuaranteedPeriod(){
      return this.typepayments.some(type=>type.returnType == 1)
    },
    hasGuaranteedPayments(){
      return this.typepayments?.some(type=> type.returnType == 1) && !!this.contract?.guaranteedPayment?.id && parseFloat(this.contract?.guaranteedPayment?.guaranteedAmount) > 0
    },
    tableColumnsAuto(){
      if (!this.contractIsCancelled) {
        return [].concat(this.tableColumns, [
          { key: 'actions', label: 'Acciones', class: 'custom-padding-payment-types text-center' },
        ])
      }
      return this.tableColumns
    }
  },
  methods: {
    ...mapActions('fivesClubContracts', ['fetchContractPaymentTypes', 'deleteContractPaymentTypes', 'fetchGuaranteedPaymentReport', 'updateContractGuaranteedAmount', 'saveContractGuaranteedAmount']),
    ...mapMutations('fivesClubContracts', ['setContracts','setIsLoadingContracts']),
    formatBudget(item, data){
      if (item[data]) item[data] = numberWithCommas(item[data]);
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "Cambiando tipos de pago del contrato",
        text: `¿Desea actualizar el contrato`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,

      })
      return isConfirmed
    },
    addPaymentType(){
      this.$emit('add-new-payment-type')
    },
    editPaymentType(id){
      this.$emit('edit-payment-type', id)
    },
    formatThisDate(date){
      return formatDateSpecific({date, toFormat: 'DD/MM/YYYY'})
    },
    async saveGuaranteedAmount(){
      this.isSavingGuaranteedAmount = true
      const {id=null, guaranteedAmount, periodicity} = this.contract.guaranteedPayment
      const payload = {
        id, guaranteedAmount, periodicity,
        idContract: this.contract.id,
        idUser: this.user.idUser
      }
      if (typeof payload?.guaranteedAmount == 'string') payload.guaranteedAmount = payload?.guaranteedAmount?.split(",").join("")

      if (!!payload.id) await this.updateContractGuaranteedAmount(payload)
      else {
        const newAmountId = await this.saveContractGuaranteedAmount(payload)
        this.contract.guaranteedPayment.id = newAmountId
      }

      this.$emit('reload-payment-types')
      this.isSavingGuaranteedAmount = false
    },
    async getGuaranteedPaymentsReport(id){
      this.isDownloadingReport = true
      const {id:idContract, code} = this.contract
      await this.fetchGuaranteedPaymentReport({idContract, code, getExcel:true})
      this.isDownloadingReport = false
    },
    formatCurrency(amount){
      return toCurrency(amount)
    },
    async deletePaymentType(id){
      const payloadAction = { title: '¿Está seguro de borrar este registro?', text: 'Esta acción no se puede deshacer', showDenyButton: true, confirmButtonText: 'Sí, borrar', denyButtonText: 'Cancelar'}
      const { isConfirmed } = await this.$swal.fire(payloadAction)
      if( isConfirmed ){
        this.isDeletingPaymentType = id
        const response = await this.deleteContractPaymentTypes({id})
        if (response) this.$emit('reload-payment-types')
        this.isDeletingPaymentType = false
      }
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.payment-type-table{
  min-height: 150px;
}
.payment-type-table-font-size{
  font-size: 0.9rem;
}
.custom-padding-payment-types{
  padding: 0.6rem !important;
}
</style>