<template>
  <b-row>
    <b-col cols="12">
      <h4 v-if="!!paymentFormatData.id">Editando tipo de pago</h4>
      <h4 v-if="!paymentFormatData.id">Creando tipo de pago</h4>
    </b-col>
    <b-col>
      <ValidationObserver
        v-slot="{ invalid }"
        tag="form"
        :disabled="true"
        ref="PaymentTypeForm"
      >
        <b-form @submit.prevent="savePaymentFormat">
          <b-row>
            <b-col>
              <b-row>
                <b-col md="3">
                  <ValidationProvider name="Fecha de Inicio" rules="required">
                    <b-form-group slot-scope="{ errors }" class="">
                      <label>Fecha de Inicio*</label>
                      <flat-pickr
                        :config="datePickerConfig()"
                        :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"

                        v-model="paymentFormatData.dateInit"
                        :disabled="isSavingPaymentFormat"
                        placeholder="Seleccione fecha de Inicio"
                        :state="errors.length > 0 ? false : null"
                      />
                        <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="Fecha de Término" rules="required">
                    <b-form-group slot-scope="{ errors }" class="">
                      <label>Fecha de Término*</label>
                      <flat-pickr
                        :config="datePickerConfig(paymentFormatData.dateInit)"
                        :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"

                        v-model="paymentFormatData.dateEnd"
                        :disabled="isSavingPaymentFormat"
                        placeholder="Seleccione fecha de Término"
                        :state="errors.length > 0 ? false : null"
                      />
                        <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!--  -->

                <b-col md="3">
                  <ValidationProvider name="Tipo de pago" rules="required">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Tipo de pago*</label>
                      <b-form-select
                        :state="!valid || errors.length > 0 ? false : null"
                        v-model="paymentFormatData.paymentType"
                        :disabled="isSavingPaymentFormat"
                      >
                        <option :value="null" disabled>Seleccione tipo de pago</option>

                        <option v-for="pt in paymentTypes" :key="pt.id" :value="pt.id">
                          {{ pt.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="3">
                  <ValidationProvider name="Tipo de retorno" rules="required">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Tipo de retorno*</label>
                      <b-form-select
                        :state="!valid || errors.length > 0 ? false : null"
                        v-model="paymentFormatData.returnType"
                        :disabled="isSavingPaymentFormat"
                      >
                        <option :value="null" disabled>Seleccione tipo de retorno</option>

                        <option v-for="ret in retornos" :key="ret.id" :value="ret.id">
                          {{ ret.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" class="mt-1">
              <div class="float-right d-flex">
                <b-button
                  variant="warning"
                  type="button"
                  class="mr-1"
                  @click="goToList"
                  :disabled="(isSavingPaymentFormat || isUpdatingContract || isCancellingContract)"
                > Cancelar</b-button>

                <b-button
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="(invalid || isSavingPaymentFormat || isUpdatingContract || isCancellingContract)"
                >
                  <b-spinner small v-if="isSavingPaymentFormat" /> Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-col>

  </b-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import * as moment from 'moment'
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'
import flatPickr from "vue-flatpickr-component";
import { numberWithCommas, getLocalFilePath, showAlertMessage } from "@/helpers/helpers"


export default {
  directives: { can },
	mixins: [utils, acl],
  components: {
    flatPickr,
  },
  props: {
    paymentFormatData: {
      type: Object,
      required: true,
    },
    isUpdatingContract: {
      type: Boolean,
      default: false
    },
    isCancellingContract: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      file: null,
      isSavingPaymentFormat: false,
      deleteRemodelationReport: false,
    }
  },
  computed:{
    ...mapState("auth", ["user"]),
    ...mapState('fivesClubCatalogs', ['paymentTypes', 'retornos']),
    ...mapState("fivesClubContracts", [ "selectedContract", "contractstatus", "contracts", "requestContracts", "remodelationTypes"]),
    editableResalePrice(){
      const statusValid = ["PROPUESTA","FIRMADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      const valid = statusValid.includes(this.selectedContract.statuscontractname)
      return !valid
    },
    contractYears(){
      const minDate = moment(this.selectedContract?.dateStart).format("YYYY")
      const diference = parseInt(this.selectedContract.duration)
      const years = []

      for (let idx = 0; idx <= diference; idx++) {
        years.push(parseInt(minDate) + idx)
      }
      return years
    }
  },
  methods: {
    ...mapActions("fivesClubContracts", ["saveContractPaymentTypes", "updateContractPaymentTypes"]),
    formatBudget(item, data){
      if (item[data]) item[data] = numberWithCommas(item[data]);
    },
    goToList(){
      this.$emit('return-to-list')
    },
    async savePaymentFormat(){
      this.isSavingPaymentFormat = true
      let response = false

      const payload = {
        idContract: this.selectedContract.id,
        id: this.paymentFormatData.id || null,
        idUser: this.user.idUser,

        dateInit: !!this.paymentFormatData.dateInit ? this.paymentFormatData.dateInit : null,
        dateEnd: !!this.paymentFormatData.dateEnd ? this.paymentFormatData.dateEnd : null,
        paymentType: this.paymentFormatData.paymentType,
        returnType: this.paymentFormatData.returnType,
        guaranteedAmount: this.paymentFormatData.guaranteedAmount
      }
      if (typeof payload?.guaranteedAmount == 'string') payload.guaranteedAmount = payload?.guaranteedAmount?.split(",").join("")

      if (!!this.paymentFormatData.id) response = await this.updateContractPaymentTypes(payload)
      else response = await this.saveContractPaymentTypes(payload)

      if (response) {
        if (!!this.paymentFormatData.id) showAlertMessage("Tipo de pago actualizado","BellIcon","Se han actualizado los datos","success",4000,"bottom-right")
        else showAlertMessage("Tipo de pago guardado","BellIcon","Se ha guardado el nuevo tipo de pago","success",4000,"bottom-right")

        this.$emit('reload-payment-types')
        this.$emit('return-to-list')
      } else if (!response) {
        showAlertMessage( "No se guardó el tipo de pago", "BellIcon", "Por favor, inténtelo más tarde", "warning", 4000, "bottom-right" )
      }
      this.isSavingPaymentFormat = false
    },
    datePickerConfig(minDate=null){
      return { altInput: true, altFormat: 'd/m/Y', minDate: minDate || this.selectedContract.dateStart, maxDate: this.selectedContract.dateEnd, onChange: this.setUpdatingRegisters()}
    },
    setUpdatingRegisters(){
      this.isUpdating = true
    }
  }
}
</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-flatpicker.scss";

  .flatpickr-color-remodelation{
    background-color: white !important;
  }
  .flatpickr-color-remodelation:disabled{
    background-color: #efefef !important;
  }
  .image-delete-button{
    padding: 0.4rem 0.6rem;
    font-size: smaller;
  }
  .file-name-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
    font-size: smaller;
  }
</style>