<template>
  <div>
    <b-row class="border rounded p-1">
      <b-col>
        <PaymentTypesList
          v-if="!showForm"
          :typepayments="contractPaymentTypes"
          :isLoadingPaymentTypes="isLoadingPaymentTypes"
          :contract="selectedContract"
          :contractIsCancelled="contractIsCancelled"
          @add-new-payment-type="createPaymentType"
          @edit-payment-type="editPaymentType"
          @reload-payment-types="fetchPaymentTypes"
        />
        <PaymentTypesForm
          v-if="showForm && canSeeForm"
          :paymentFormatData="paymentTypeData"
          :contractIsCancelled="contractIsCancelled"
          :showForm="showForm"
          @return-to-list="disableForm"
          @reload-payment-types="fetchPaymentTypes"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'
import PaymentTypesList from "@/modules/fivesClub/components/contracts/paymentTypes/PaymentTypesList"
import PaymentTypesForm from "@/modules/fivesClub/components/contracts/paymentTypes/PaymentTypesForm"

import { numberWithCommas, showAlertMessage } from "@/helpers/helpers"

export default {
  directives: { can },
	mixins: [utils, acl],
  components: {
    PaymentTypesList,
    PaymentTypesForm
  },
  props: {
    tabIndex: {
      type: Number,
      required: true
    },
    firstSearch: {
      type: Boolean,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true
    },
    firstSearch: {
      type: Boolean,
      required: true,
    },
    contractIsCancelled: {
      type: Boolean,
      required: true,
      default: true
    },
  },
  data(){
    return {
      showForm: false,
      isLoadingPaymentTypes: false,
      paymentTypeData: {
        dateInit: null,
        dateEnd: null,
        paymentType: null,
        returnType: null,
      },
    }
  },
  watch: {
    tabIndex: {
			deep: true,
			async handler() {
        if (this.tabIndex == 1){
          if (this.contractPaymentTypes?.length == 0 && !this.firstSearch) {
            await this.fetchPaymentTypes()
            this.$emit('set-first-search', 'firstSearchPaymentTypes')
          }
        }
			},
		}
  },

  computed:{
    ...mapState('auth', ['user']),
    ...mapState("fivesClubContracts", [ "selectedContract", "contractPaymentTypes" ]),
    canSeeForm(){
      return !this.contractIsCancelled
    }
  },
  methods: {
    ...mapMutations("fivesClubContracts", ["setContractPaymentTypes"]),
    ...mapActions("fivesClubContracts", ["fetchContractPaymentTypes", "updateContractPaymentTypes"]),
    createPaymentType(){
      this.showForm = true
    },
    async editPaymentType(id){
      const paymentType = await this.fetchContractPaymentTypes({id})
      if (!!paymentType) {
        this.paymentTypeData = paymentType
        if (!!paymentType?.guaranteedAmount) paymentType.guaranteedAmount = numberWithCommas(paymentType?.guaranteedAmount)
        this.showForm = true
      } else showAlertMessage('No se pudo cargar registro', 'infoIcon', 'No se pudo cargar este registro, por favor inténtelo más tarde', 'warning', 4000)
    },

    disableForm(){
      this.showForm = false
      this.paymentTypeData = {
        dateInit: null,
        dateEnd: null,
        paymentType: null,
        returnType: null,
      }
    },

    async fetchPaymentTypes(){
      this.isLoadingPaymentTypes = true
      const response = await this.fetchContractPaymentTypes({idContract: this.selectedContract?.id})
      if (!!this.selectedContract.guaranteedPayment?.guaranteedAmount) this.selectedContract.guaranteedPayment.guaranteedAmount = numberWithCommas(this.selectedContract.guaranteedPayment?.guaranteedAmount)
      this.setContractPaymentTypes(response)
      this.isLoadingPaymentTypes = false
    },
  }
}
</script>